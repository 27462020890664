import React, { useState, useRef, useEffect, useMemo, useCallback, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../contexts/ThemeContext';
import {
  ChatbotContainer,
  ChatbotButton,
  MessageBubble,
  MessageTimestamp
} from './ChatBotStyles';
// import useLocalStorage from '../hooks/useLocalStorage';
import useForm from '../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { translate } from '../i18n';
import { debounce } from 'lodash';
import { motion } from 'framer-motion';
import { FaComments, FaTimes } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
// import { db } from '../firebaseConfig';

// Define constants
const MAX_MESSAGE_LENGTH = 500;

// Lazy load ChatWindow component
const LazyLoadedChatWindow = lazy(() => import('./ChatWindow'));
// Extract helper functions
const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const formatTimestamp = (timestamp) => new Date(timestamp).toLocaleTimeString();

// Error Boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Chatbot error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong with the chatbot. Please try again later.</h1>;
    }

    return this.props.children;
  }
}

const ChatBot = React.memo(({ quickReplyOptions, backOnlineTime }) => {
  // Optimize state management
  const [chatState, setChatState] = useState({
    isOpen: false,
    isTyping: false,
    showWelcome: true,
    showAutomatedMessage: false,
    selectedOption: null,
    userEmail: '',
    showEmailInput: false,
    error: '',
    showAdditionalDetailsInput: false,
  });

  const [messages, setMessages] = useState([]);
  const { isDarkMode } = useTheme();
  const chatBodyRef = useRef(null);

  const { values, handleChange, resetForm } = useForm({
    message: ''
  });

  const { t } = useTranslation();

  // Improve memoization
  const theme = useMemo(() => ({
    // ... (keep the existing theme object)
    timestampColor: isDarkMode ? '#888' : '#666',
  }), [isDarkMode]);

  const memoizedQuickReplyOptions = useMemo(() => quickReplyOptions, [quickReplyOptions]);

  // Optimize clearOldMessages with useCallback
  const clearOldMessages = useCallback(() => {
    const currentTime = Date.now();
    return messages.filter(msg => currentTime - msg.timestamp < 24 * 60 * 60 * 1000);
  }, [messages]);

  // Enhance error handling
  const handleError = useCallback((errorMessage) => {
    console.error(errorMessage);
    setChatState(prev => ({ ...prev, error: errorMessage }));
    // You could also log this error to an error tracking service
  }, []);

  // Optimize toggleChat
  const toggleChat = useCallback(() => {
    console.log("Toggle chat called");
    setChatState(prev => {
      const newIsOpen = !prev.isOpen;
      console.log("New isOpen state:", newIsOpen);
      if (newIsOpen) {
        const updatedMessages = clearOldMessages();
        setMessages(updatedMessages);
        return {
          ...prev,
          isOpen: true,
          showWelcome: true,
          showAutomatedMessage: true,
        };
      } else {
        setMessages([]);
        return {
          ...prev,
          isOpen: false,
          showAutomatedMessage: false,
        };
      }
    });
  }, [clearOldMessages, setMessages]);

  // Optimize simulateBotTyping with useCallback
  const simulateBotTyping = useCallback((duration, callback) => {
    setChatState(prev => ({ ...prev, isTyping: true }));
    setTimeout(() => {
      setChatState(prev => ({ ...prev, isTyping: false }));
      callback();
    }, duration);
  }, []);

  // Refactor repetitive code for adding messages
  const addMessage = useCallback((content, type = 'user') => {
    const newMessage = {
      type,
      content,
      timestamp: Date.now()
    };
    setMessages(prev => [...prev, newMessage]);
  }, [setMessages]);

  // Refactor to create a shared function for handling messages
  const handleMessage = useCallback((message, isQuickReply = false) => {
    if (message.trim() === '') return;

    addMessage(message, 'user');
    setChatState(prev => ({ 
      ...prev, 
      error: '', 
      selectedOption: isQuickReply ? message : null 
    }));
    
    simulateBotTyping(1500, () => {
      addMessage("Thank you for your message. How else can I assist you?", 'bot');
      // Reset selectedOption to show quick reply options again
      setChatState(prev => ({ ...prev, selectedOption: null }));
    });
  }, [addMessage, simulateBotTyping]);

  const handleQuickReply = useCallback((option) => {
    const specificOptions = [
      "How can I contact you?",
      "Tell me about your services",
      "I have a question",
      "I'd like to make an appointment"
    ];

    if (specificOptions.includes(option)) {
      // We'll only add the user's selection as a message here
      addMessage(option, 'user');
      
      // Update the chat state
      setChatState(prev => ({
        ...prev,
        selectedOption: option,
        showAdditionalDetailsInput: true,
        showEmailInput: false
      }));
      
      // We'll remove the bot's response from here, as it will be handled in ChatWindow.js
    } else {
      handleMessage(option, true);
    }
  }, [addMessage, setChatState, handleMessage]);

  // Optimize handleEmailSubmit
  const handleEmailSubmit = useCallback(async (email) => {
    if (!isValidEmail(email)) {
      setChatState(prev => ({ ...prev, error: 'Please enter a valid email address' }));
      return;
    }

    try {
      // Get the current user (if authenticated)
      const auth = getAuth();
      const user = auth.currentUser;

      // Prepare the data to be saved
      const emailData = {
        email: email,
        timestamp: new Date(),
        userId: user ? user.uid : null, // Include user ID if authenticated
        selectedOption: chatState.selectedOption,
        additionalDetails: chatState.additionalDetails // Make sure to capture this in your state
      };

      const firestore = getFirestore();
      const docRef = await addDoc(collection(firestore, 'chatEmails'), emailData);
      console.log('Document written with ID: ', docRef.id);

      setChatState(prev => ({ ...prev, userEmail: email, error: '', showEmailInput: false }));
      addMessage(email, 'user');
      
      simulateBotTyping(1500, () => {
        addMessage("Thank you. We'll get back to you soon.", 'bot');
      });
    } catch (error) {
      console.error('Error saving email to Firebase: ', error);
      setChatState(prev => ({ ...prev, error: 'An error occurred. Please try again.' }));
    }
  }, [addMessage, simulateBotTyping, chatState.selectedOption, chatState.additionalDetails]);

  // Optimize clearChat
  const clearChat = useCallback(() => {
    setMessages([]);
    setChatState(prev => ({
      ...prev,
      selectedOption: null,
      showEmailInput: false,
      showWelcome: true,
      showAutomatedMessage: true,
    }));
  }, [setMessages]);

  // Update handleUserMessage to use the shared function
  const handleUserMessage = useCallback((message) => {
    handleMessage(message, false);
  }, [handleMessage]);

  // Improve accessibility
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setChatState(prev => ({ ...prev, isOpen: false }));
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Optimize performance with debounce
  const debouncedScrollToBottom = useMemo(
    () => debounce(() => {
      if (chatBodyRef.current) {
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      }
    }, 100),
    []
  );

  useEffect(() => {
    debouncedScrollToBottom();
  }, [messages.length, debouncedScrollToBottom]);

  // Implement lazy loading for messages
  const [visibleMessages, setVisibleMessages] = useState([]);
  const messagesPerBatch = 20;

  useEffect(() => {
    setVisibleMessages(messages.slice(-messagesPerBatch));
  }, [messages]);

  const loadMoreMessages = useCallback(() => {
    setVisibleMessages(prev => [
      ...messages.slice(-(prev.length + messagesPerBatch), -prev.length),
      ...prev
    ]);
  }, [messages]);

  // Memoize renderMessages
  const renderMessages = useMemo(() => {
    let currentSender = null;
    return visibleMessages.map((msg, index) => {
      const showAvatar = msg.type !== currentSender;
      currentSender = msg.type;
      return (
        <React.Fragment key={index}>
          <MessageBubble $isUser={msg.type === 'user'} theme={theme} $showAvatar={showAvatar}>
            {msg.content}
            <MessageTimestamp>{formatTimestamp(msg.timestamp)}</MessageTimestamp>
          </MessageBubble>
          {index === visibleMessages.length - 1 && msg.type === 'user' && (
            <span aria-live="polite" className="sr-only">{t('messageSent')}</span>
          )}
        </React.Fragment>
      );
    });
  }, [visibleMessages, theme, t]);

  const setUserEmail = useCallback((email) => {
    setChatState(prev => ({ ...prev, userEmail: email }));
  }, []);

  return (
    <ChatbotContainer>
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <ChatbotButton 
          onClick={toggleChat} 
          aria-label={chatState.isOpen ? t('closeChat') : t('openChat')} 
          theme={theme}
          aria-expanded={chatState.isOpen}
        >
          {chatState.isOpen ? <FaTimes /> : <FaComments />}
        </ChatbotButton>
      </motion.div>
      {chatState.isOpen && (
        <ErrorBoundary onError={handleError}>
          <Suspense fallback={<div aria-live="polite">{translate('loading')}</div>}>
            <LazyLoadedChatWindow
              isOpen={chatState.isOpen}
              theme={theme}
              messages={messages}
              chatState={chatState}
              setChatState={setChatState}
              renderMessages={renderMessages}
              values={values}
              handleChange={handleChange}
              handleUserMessage={handleUserMessage}
              handleEmailSubmit={handleEmailSubmit}
              handleQuickReply={handleQuickReply}
              quickReplyOptions={memoizedQuickReplyOptions}
              backOnlineTime={backOnlineTime}
              toggleChat={toggleChat}
              clearChat={clearChat}
              MAX_MESSAGE_LENGTH={MAX_MESSAGE_LENGTH}
              resetForm={resetForm}
              backOnlineMessage={translate('chatbot.backOnlineAt', { time: backOnlineTime })}
              welcomeMessage={translate('chatbot.welcomeMessage')}
              automatedMessage={translate('chatbot.automatedMessage')}
              noQuickRepliesMessage={translate('chatbot.noQuickReplies')}
              visibleMessages={visibleMessages}
              loadMoreMessages={loadMoreMessages}
              handleError={handleError}
              setMessages={setMessages}
              simulateBotTyping={simulateBotTyping}
              isTyping={chatState.isTyping}
              userEmail={chatState.userEmail}
              setUserEmail={setUserEmail}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </ChatbotContainer>
  );
});

ChatBot.propTypes = {
  backOnlineTime: PropTypes.string.isRequired,
  quickReplyOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default React.memo(ChatBot);
