import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';

const Home = lazy(() => import('./pages/Home'));
const Projects = lazy(() => import('./pages/Projects'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogPost = lazy(() => import('./components/BlogPost/BlogPost'));
const Videos = lazy(() => import('./pages/Videos'));
const Newsletter = lazy(() => import('./pages/Newsletter'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'));
const PaymentCancel = lazy(() => import('./pages/PaymentCancel'));
const StripeDonation = lazy(() => import('./components/StripeDonation'));
const NotFound = lazy(() => import('./components/NotFound'));

// const Services = lazy(() => import('./pages/Services'));
// const ProjectReview = lazy(() => import('./pages/ProjectReview'));
// const SubPage = lazy(() => import('./pages/SubPage'));
const AdminBlog = lazy(() => import('./components/AdminBlog/AdminBlog'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));

const AppRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:id" element={<BlogPost />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/newsletter" element={<Newsletter />} />
      <Route path="/donate" element={<StripeDonation />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-cancel" element={<PaymentCancel />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      {/* <Route path="/services" element={<Services />} /> */}
      {/* <Route path="/service/:serviceId" element={<SubPage />} /> */}
      {/* <Route path="/service/:serviceId/schedule" element={
        <PrivateRoute>
          <SubPage isScheduling={true} />
        </PrivateRoute>
      } /> */}
     {/* <Route path="/project-review" element={<ProjectReview />} /> */}
      <Route
        path="/admin/blog"
        element={
          <PrivateRoute adminOnly={true}>
            <AdminBlog />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>
);

export default AppRoutes;
