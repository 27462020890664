import enTranslations from '../translations/en.json';
import arTranslations from '../translations/ar.json';

const translations = {
  en: enTranslations,
  ar: arTranslations
};

export const getTranslation = (key, language) => {
  const keys = key.split('.');
  let translation = translations[language];
  
  for (const k of keys) {
    translation = translation[k];
    if (translation === undefined) {
      console.warn(`Translation not found for key: ${key} in language: ${language}`);
      return key;
    }
  }
  
  return translation;
};
