import React, { useEffect, useRef, useCallback } from 'react';
import { useTheme } from '../contexts/ThemeContext';

const MouseTrail = () => {
  const canvasRef = useRef(null);
  const { isDarkMode } = useTheme();

  const resizeCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    let particles = [];
    let animationFrameId;

    const createParticle = (x, y) => {
      return { x, y, size: 2, life: 20, velocity: { x: Math.random() * 2 - 1, y: Math.random() * 2 - 1 } };
    };

    const drawParticle = (particle) => {
      const alpha = particle.life / 20;
      ctx.fillStyle = isDarkMode
        ? `rgba(255, 255, 255, ${alpha})`
        : `rgba(0, 0, 0, ${alpha})`;
      ctx.fillRect(particle.x, particle.y, particle.size, particle.size);
    };

    const updateParticles = () => {
      particles = particles.filter(particle => {
        particle.life--;
        particle.x += particle.velocity.x;
        particle.y += particle.velocity.y;
        return particle.life > 0;
      });
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      updateParticles();
      particles.forEach(drawParticle);
      animationFrameId = requestAnimationFrame(animate);
    };

    const handleMouseMove = (event) => {
      particles.push(createParticle(event.clientX, event.clientY));
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    window.addEventListener('mousemove', handleMouseMove);
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      window.removeEventListener('mousemove', handleMouseMove);
      cancelAnimationFrame(animationFrameId);
    };
  }, [resizeCanvas, isDarkMode]);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, pointerEvents: 'none', zIndex: 9999 }} />;
};

export default MouseTrail;
