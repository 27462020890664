import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Spinner from './Spinner';

const PrivateRoute = ({ children, adminOnly = false }) => {
  const { currentUser, loading } = useAuth();
  const location = useLocation();
  const pathname = location.pathname;

  if (loading) {
    return <Spinner />;
  }

  if (!currentUser) {
    const state = {
      from: pathname,
      isProjectReview: pathname.includes('project-review'),
      serviceId: pathname.split('/').pop()
    };

    return (
      <Navigate 
        to="/login" 
        state={state}
        replace 
      />
    );
  }

  if (adminOnly && !currentUser?.isAdmin) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default PrivateRoute;
