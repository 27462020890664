import React, { createContext, useState, useContext, useEffect } from 'react';
import { setLanguage, translate } from '../i18n';

// Create a context for managing language state
const LanguageContext = createContext();

// Create a provider component for the language context
export const LanguageProvider = ({ children }) => {
  // Initialize the language state with 'en' (English) as the default
  const [language, setLanguageState] = useState('en');

  // Function to toggle between 'en' and 'ar'
  const toggleLanguage = async () => {
    const newLang = language === 'en' ? 'ar' : 'en';
    await setLanguage(newLang);
    setLanguageState(newLang);
  };

  useEffect(() => {
    setLanguage(language);
  }, [language]);

  useEffect(() => {
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.lang = language;
    document.body.style.fontFamily = language === 'ar' ? "'Noto Naskh Arabic', Arial, sans-serif" : "'Fira Code', 'Roboto Mono', 'Courier New', monospace";
  }, [language]);

  // Value object to be provided to consumers of this context
  const value = {
    language,
    toggleLanguage,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook for easy access to the language context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

// Custom hook for easy translation usage
export const useTranslation = () => {
  const t = (key, params, count) => translate(key, params, count);
  return t;
};