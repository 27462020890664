import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import styled from 'styled-components';
import { FaSun, FaMoon } from 'react-icons/fa'; // Importing icons

const ToggleButton = styled.button`
  background: none; /* Remove background */
  color: ${props => props.$isDarkMode ? 'var(--primary-dark)' : 'var(--primary-light)'}; /* Set icon color */
  border: none;
  cursor: pointer;
  font-size: 24px; /* Increase icon size */
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }
`;

const ThemeToggle = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <ToggleButton 
      onClick={toggleTheme} 
      $isDarkMode={isDarkMode} 
      aria-label={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
      aria-pressed={isDarkMode}
    >
      {isDarkMode ? <FaSun /> : <FaMoon />}
    </ToggleButton>
  );
};

export default ThemeToggle;