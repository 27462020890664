import React from 'react';

const Footer = () => (
  <footer style={styles.footer}>
    <span>© DataDiwan 2024</span>
  </footer>
);

const styles = {
  footer: {
    textAlign: 'center',
    marginTop: '40px',
    fontSize: '14px',
  },
  link: {
    color: '#1c1',
    textDecoration: 'none',
  },
};

export default Footer;
