import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { 
  auth, 
  db,
} from '../firebaseConfig';
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCheckingAdmin, setIsCheckingAdmin] = useState(false);

  async function signup(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      console.log("User created in Firebase Auth:", user.uid);

      try {
        // Create the user document in Firestore
        await setDoc(doc(db, 'users', user.uid), {
          email: user.email,
          isAdmin: false
        });
        console.log("User document created in Firestore");

        // Add email to userEmails collection
        await setDoc(doc(db, 'userEmails', user.email), {
          uid: user.uid
        });
        console.log("Email added to userEmails collection");

      } catch (firestoreError) {
        console.error("Error writing to Firestore:", firestoreError);
        console.error("Error details:", firestoreError.code, firestoreError.message);
        throw firestoreError;
      }

      setCurrentUser(user);
      return user;
    } catch (error) {
      console.error("Signup error:", error);
      console.error("Error details:", error.code, error.message);
      throw error;
    }
  }

  async function login(email, password, rememberMe) {
    try {
      console.log("Attempting to log in with email:", email);
      
      // Check if the email exists in userEmails collection
      const emailDoc = await getDoc(doc(db, 'userEmails', email));
      if (!emailDoc.exists()) {
        console.log("Email not found in userEmails collection");
        throw new Error('auth/user-not-found');
      }

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.exists()) {
        throw new Error('auth/user-document-not-found');
      }

      const userData = userDoc.data();
      const isAdmin = userData.isAdmin === true;
      const userWithAdminStatus = { ...user, isAdmin };
      
      if (rememberMe) {
        await setPersistence(auth, browserLocalPersistence);
      } else {
        await setPersistence(auth, browserSessionPersistence);
      }

      setCurrentUser(userWithAdminStatus);
      return userWithAdminStatus;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  }

  async function signInWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // Add a small delay
      await new Promise(resolve => setTimeout(resolve, 100));

      // Check if user document exists, if not create one
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          email: user.email,
          isAdmin: false // Set default admin status
        });

        // Add email to userEmails collection
        await setDoc(doc(db, 'userEmails', user.email), {
          uid: user.uid
        });
      }
      
      // Fetch the user data including admin status
      const updatedUserDoc = await getDoc(userDocRef);
      const userData = updatedUserDoc.data();
      
      const userWithAdminStatus = { ...user, isAdmin: userData.isAdmin };
      setCurrentUser(userWithAdminStatus);
      return userWithAdminStatus;
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
      if (error.code === 'auth/popup-closed-by-user') {
        // Handle case where user closed the popup
        throw new Error('Sign-in cancelled by user');
      } else if (error.code === 'auth/cancelled-popup-request') {
        // Handle case where multiple popups were opened
        throw new Error('Multiple sign-in attempts detected');
      } else if (error.code === 'auth/network-request-failed') {
        // Handle network errors
        throw new Error('Network error occurred during sign-in');
      } else if (error.code === 'auth/user-disabled') {
        // Handle disabled user accounts
        throw new Error('This account has been disabled');
      } else if (error.code === 'auth/operation-not-allowed') {
        // Handle if Google sign-in is not enabled in Firebase console
        throw new Error('Google sign-in is not enabled for this app');
      } else {
        // Handle other errors
        throw new Error('An error occurred during sign-in: ' + error.message);
      }
    }
  }

  const logout = useCallback(async () => {
    try {
      await signOut(auth);
      setCurrentUser(null); // Explicitly clear the current user
      return true;
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  }, []);

  const checkIfUserIsAdmin = useCallback(async (uid) => {
    setIsCheckingAdmin(true);
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      return userDoc.exists() && userDoc.data().isAdmin === true;
    } catch (error) {
      console.error("Error checking admin status:", error);
      return false;
    } finally {
      setIsCheckingAdmin(false);
    }
  }, []);

  const updateProfile = async (uid, profileData) => {
    try {
      const userRef = doc(db, 'users', uid);
      
      // First check if the document exists
      const userSnap = await getDoc(userRef);
      
      // Remove any potential isAdmin field from profileData for security
      const { isAdmin, ...safeProfileData } = profileData;
      
      if (userSnap.exists()) {
        // Update existing document while preserving isAdmin status
        const existingData = userSnap.data();
        await setDoc(userRef, {
          ...existingData,
          ...safeProfileData,
          isAdmin: existingData.isAdmin, // Preserve existing isAdmin status
          updatedAt: new Date().toISOString()
        }, { merge: true });
      } else {
        // Create new document (new user)
        await setDoc(userRef, {
          ...safeProfileData,
          isAdmin: false, // Default to non-admin
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      }
      
      return true;
    } catch (error) {
      console.error('Error updating profile:', error);
      throw new Error('Failed to update profile');
    }
  };

  const checkIsAdmin = async (uid) => {
    try {
      const userRef = doc(db, 'users', uid);
      const userSnap = await getDoc(userRef);
      return userSnap.exists() && userSnap.data().isAdmin === true;
    } catch (error) {
      console.error('Error checking admin status:', error);
      return false;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      try {
        if (user) {
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            const userData = docSnap.data();
            // Combine auth user with Firestore data
            const enhancedUser = {
              ...user,
              isAdmin: userData.isAdmin || false,
              // Add any other user data from Firestore
              profile: userData
            };
            setCurrentUser(enhancedUser);
          } else {
            // New user - create profile
            const newUserData = {
              email: user.email,
              isAdmin: false,
              createdAt: new Date().toISOString()
            };
            await setDoc(docRef, newUserData);
            setCurrentUser({ ...user, isAdmin: false, profile: newUserData });
          }
        } else {
          setCurrentUser(null);
        }
      } catch (error) {
        console.error("Error setting user data:", error);
        // Still set the basic user object if Firestore fails
        setCurrentUser(user ? { ...user, isAdmin: false } : null);
      } finally {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loading,
    isCheckingAdmin,
    signup,
    login,
    logout,
    signInWithGoogle,
    checkIfUserIsAdmin,
    updateProfile,
    checkIsAdmin,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
