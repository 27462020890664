import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useTheme } from '../contexts/ThemeContext';
import styled from 'styled-components';

const ToggleButton = styled.button`
  background: none;
  color: ${props => props.$isDarkMode ? 'var(--primary-dark)' : 'var(--primary-light)'}; /* Set icon color */
  border: none;
  cursor: pointer;
  font-size: 19px; /* Adjust font size for text */
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }
`;

const LanguageToggle = () => {
  const { language, toggleLanguage } = useLanguage();
  const { isDarkMode } = useTheme();

  return (
    <ToggleButton 
      onClick={toggleLanguage} 
      $isDarkMode={isDarkMode} 
      aria-label={`Switch to ${language === 'en' ? 'Arabic' : 'English'}`}
    >
      {language === 'en' ? 'AR' : 'EN'}
    </ToggleButton>
  );
};

export default LanguageToggle;