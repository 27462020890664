import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getApp } from "firebase/app";

const initializeFirebase = async () => {
  const loadRecaptchaScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const initializeAppCheckWithRetry = async () => {
    const maxRetries = 3;
    let retries = 0;

    while (retries < maxRetries) {
      try {
        await loadRecaptchaScript();
        await initializeAppCheck(getApp(), {
          provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
          isTokenAutoRefreshEnabled: true,
        });
        console.log('AppCheck initialized successfully');
        break;
      } catch (error) {
        console.error('Error initializing AppCheck:', error);
        retries++;
        if (retries === maxRetries) {
          console.error('Max retries reached. AppCheck initialization failed.');
        } else {
          console.log(`Retrying AppCheck initialization (${retries}/${maxRetries})...`);
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
    }
  };

  await initializeAppCheckWithRetry();
};

export default initializeFirebase;
