import { createGlobalStyle } from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#fff'};
    color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#000'};
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ isDarkMode }) => isDarkMode ? '#4caf50' : '#2e7d32'};
  }

  a {
    color: ${({ isDarkMode }) => isDarkMode ? '#81c784' : '#4caf50'};
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const GlobalStylesWrapper = () => {
  const { isDarkMode } = useTheme();
  return <GlobalStyles isDarkMode={isDarkMode} />;
};


export default GlobalStylesWrapper;